.checkbox-container {
  display: block;
  font-size: 0.6875rem;
  min-height: 24px;
  padding-left: 32px;
  position: relative;
  user-select: none;

  strong {
    font-size: 0.75rem;
  }
}

.checkbox-container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark {
  background-color: #fff;
  border: 1px solid #51ad95;
  border-radius: 8px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.checkbox-container input:checked ~ .checkmark {
  border-color: #004c44;
}

.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}

.checkmark::after {
  border: solid #004c44;
  border-width: 0 1px 1px 0;
  content: '';
  display: none;
  height: 13px;
  left: 9px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 5px;
}
