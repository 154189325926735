$base-spacing: 0.5rem;
$body-bg: #f0f5f4;
$body-color: #333;
$p-color: #7d828b;
$green: #51ad95;
$green2: #91d1ba;
$white: #fff;

$font-weights: (
  normal: 400,
  bold: 600,
);

$headers: (
  h1: (
    font-size: 20px,
    line-height: 24px,
  ),
  h2: (
    font-size: 16px,
    line-height: 18px,
  ),
);
