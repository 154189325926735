.btn-primary {
  align-items: center;
  background: #51ad95;
  border: none;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &:disabled {
    background: #f4f4f4;
    color: #666;
  }
  &:hover {
    color: #fff;
  }
}

.btn-outline {
  background: #fff;
  border: 2px solid #51ad95;
  border-radius: 8px;
  color: #51ad95;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  min-width: 136px;
  padding: 10px 40px;
  text-align: center;
  text-transform: uppercase;
}

.btn-outline-b-none {
  background: #fff;
  border-radius: 8px;
  color: #51ad95;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  min-width: 136px;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.btn-flat {
  background: #fff;
  border: none;
  border-radius: 8px;
  color: #51ad95;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  min-width: 136px;
  padding: 10px 14px;
  text-align: center;
  text-transform: uppercase;
}

.btn-link {
  background: transparent;
  border: none;
  color: #51ad95;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.icon-btn {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
