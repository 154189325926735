@use '../abstracts/variables';

html,
body {
  height: 100%;
}

p {
  color: variables.$p-color;
  margin: 0;
}

a {
  color: #51ad95;
  font-weight: 600;
}

.w-100 {
  width: 100%;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

hr {
  border: none;
  margin: 0;
}

.step-content {
  flex: 1 0 auto;
}

.fs-0 {
  flex-shrink: 0;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.iti {
  width: 100%;
}
