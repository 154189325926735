$backdrop-background-color: rgba(black, 0.6);
$dialog-container-background-color: white;

.dialog-backdrop {
  background-color: $backdrop-background-color;
}

.dialog-container {
  background-color: $dialog-container-background-color;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.dialog-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  padding-bottom: 8px;
}

.dialog-content {
  flex: 1;
  overflow: auto;
  padding: 16px;
}
