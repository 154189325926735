// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$mol-limo-registration-palette: (
  100: #51ad95,
  500: #51ad95,
  700: #51ad95,
  contrast: (),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mol-limo-registration-primary: mat.define-palette($mol-limo-registration-palette, 500);
$mol-limo-registration-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mol-limo-registration-warn: mat.define-palette(mat.$red-palette);

$mol-limo-registration-typography: mat.define-typography-config(
  $font-family: 'Gotham',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mol-limo-registration-theme: mat.define-light-theme(
  (
    color: (
      primary: $mol-limo-registration-primary,
      accent: $mol-limo-registration-accent,
      warn: $mol-limo-registration-warn,
    ),
    typography: $mol-limo-registration-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mol-limo-registration-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.mat-form-field {
  font-size: 14px;
  line-height: 18px;
}

.mol-limo {
  margin-bottom: 16px;
}

@import 'base/base';
@import 'base/reboot';
@import 'base/fonts';
@import 'base/typograhpy';
@import 'components/buttons';
@import 'components/dialog';
@import 'components/checkbox';
@import 'components/modal';
@import 'components/toolbar';

$themeColor: #004c44;

@import '~swiper/swiper';
@import '~swiper/components/effect-coverflow/effect-coverflow';
@import '~swiper/components/pagination/pagination';
