.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.54);
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal-content {
  align-items: center;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 0 8px 16px -8px rgba(5, 62, 47, 0.24);
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  width: 248px;
}

.actions-content {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  width: 100%;
}

.actions {
  align-items: flex-end;
  justify-content: center;
}
