.left-actions {
  bottom: 0;
  height: 32px;
  left: 0;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: 0;
  width: 32px;
}

.left-actions-icon {
  height: 100% !important; /* stylelint-disable-line declaration-no-important */
  width: 100% !important; /* stylelint-disable-line declaration-no-important */
}

.toolbar {
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
