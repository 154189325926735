// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Gotham/Gotham-Book.woff2') format('woff2'), url('/assets/fonts/Gotham/Gotham-Book.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/Gotham/Gotham-Light.woff2') format('woff2'), url('/assets/fonts/Gotham/Gotham-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/Gotham/Gotham-Bold.woff2') format('woff2'), url('/assets/fonts/Gotham/Gotham-Bold.woff') format('woff');
}
