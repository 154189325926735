@use 'abstracts/variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@each $name, $value in variables.$font-weights {
  .font-weight-#{$name} {
    font-weight: $value;
  }
}

@each $name, $config in variables.$headers {
  .#{$name} {
    font-size: map-get($config, 'font-size');
    font-weight: map-get(variables.$font-weights, 'bold');
    line-height: map-get($config, 'line-height');
  }
}
